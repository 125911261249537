<template>
<div>
  <div class="well article_two mt-2 mt-lg-3">
    <div class="details">
      <div class="media padded-details">
        <a class="pull-left" :href="'profile/' + article.author.contributor_username">
           <img v-if="article.author.avatar" class="rounded" 
                :src="article.author.avatar" 
                width="35" height="35">
           <img v-else class="rounded" src="/static/assets/images/user.png" 
                width="35" height="35">
        </a>
        <div class="media-body">
          <a :href="'profile/' + article.author.contributor_username">
            <b>{{ article.author.get_display_name }}</b>
          </a>
          <div class="metadata">
            {{ article.sub_datetime }}
          </div>
        </div>
      </div>
      <div style="clear:both;"></div>
    </div> <!--div class="padded-details"-->

    <hr class="separator">

    <div class="row no-gutters">

      <div class="col-lg-5 p-2 text-center">   
        <a v-if="article.photo" :href="'articoli/' + article.slug">
          <img class="img-fluid" :src="article.photo">
        </a>
        <div v-else-if="article.embed_code" class="video-container">
            <iframe class="_iub_cs_activate" width="480" height="360" 
                    src="//cdn.iubenda.com/cookie_solution/empty.html" 
                    :suppressedsrc="article.embed_code" 
                    frameborder="0" allowfullscreen>
            </iframe>   
        </div>
      </div>

      <div class="col-lg-7 px-0">
        <div class="d-flex flex-column">
          <div class="p-2 pl-0 text-left">

            <h5>
              <a :href="'/articoli/' + article.slug">
                <strong>{{ article.title }}</strong>
              </a>
            </h5>

            <p class="mb-1">
              <i class="fas fa-circle-notch"></i>&nbsp; 
              <a v-for="(section, index) in article.sections" 
                 :key="section.id" 
                 :href="'/section/' + section.slug">
                 <span v-if="index > 0"> • </span>{{ section.name }}
              </a> 
            </p>
            
            <p v-if="article.description" style="mb-1 pb-0">
              <strong>{{ article.description }}</strong>
            </p>         
            <p v-else-if="article.text && article.text !== 'None'" style="mb-1 pb-0">
              {{ article.text }}...
            </p>
          </div> 
        </div>
      </div><!--col-7-->

    </div> <!--row-->

    <div class="details">            
      <div class="row">
        <hr>
        <div class="col-lg-6 pr-lg-0">
          <div class="m-2" style="color: grey; background-color: inherit; font-size: 80%;">
              <a :href="'/section/' + section.slug"><span class="validated_by">&nbsp;{{section.name}}&nbsp;</span></a>
              <span v-if="article.is_complete">&nbsp;&nbsp;{{ article.get_avg_reading_time }}</span>
              <br>      
              <div class="my-1">
                <i v-if="article.tags.length > 0" class="fas fa-tag"></i>&nbsp; 
                <a v-for="(tag, index) in article.tags" 
                  :key="tag.id" 
                  :href="'/search/tag/' + tag.slug" 
                  style="color: red">
                  <span v-if="index > 0" style="color: black"> • </span>{{ tag.name }}
                </a>
              </div>
          </div> <!--div class="toolbar"-->
        </div>
        <div class="col-lg-6 pb-3 pb-lg-0">
          <br>
          <div v-if="authenticated_user" class="comments_like_views text-center">
              <button v-if="article.liked_by_contributor"
                  class="btn btn-xs label-giornalia-neg no-shadow"
                  @click="like_article(article.id)">
                  <i class="icon icon-thumbs-up"></i> Ti piace!
              </button>
              <button v-else 
                  class="btn btn-xs label-giornalia no-shadow"
                  @click="like_article(article.id)">
                  <i class="icon icon-thumbs-up"></i> Mi Piace!
              </button>

              <button v-if="article.saved_by_contributor"
                  class="btn btn-xs label-giornalia-neg no-shadow mx-1"
                  @click="save_article(article.id)">
                  <span class="icon icon-minus"></span> Rimuovi dai Preferiti
              </button>
              <button v-else
                  class="btn btn-xs label-giornalia no-shadow mx-1"
                  @click="save_article(article.id)">
                  <span class="icon icon-plus"></span> Salva
              </button>

              <button v-if="article.shared_by_contributor" 
                  class="btn btn-xs label-giornalia-neg no-shadow"
                  @click="share_article(article.id)">
                  <span class="icon icon-minus"></span> Condiviso
              </button>
              <button v-else
                  class="btn btn-xs label-giornalia no-shadow"
                  @click="share_article(article.id)">
                  <i class="fas fa-share-square"></i> Condividi
              </button>
          </div>

          <div v-else class="comments_like_views text-center">
            <a class="btn btn-xs label-giornalia no-shadow" role="button"
                :href="'/accounts/access?next=/articoli/' + article.slug">
                <span class="icon icon-thumbs-up"></span> Mi piace!
            </a>
            <a class="btn btn-xs label-giornalia no-shadow mx-1" role="button"
                :href="'/accounts/access?next=/articoli/' + article.slug">
                <span class="icon icon-plus"></span> Salva
            </a>
            <a class="btn btn-xs label-giornalia no-shadow" role="button" 
                :href="'/accounts/access?next=/articoli/' + article.slug">
                <i class="fas fa-share-square"></i> Condividi
            </a>
          </div>
        </div>
      </div>
    </div> <!--div class="details"-->

  </div> <!--div class="well article"-->

  <div v-if="get_ad_obj !== null" class="d-lg-none">
    <a :href="get_ad_obj.link">
      <img :src="get_ad_obj.image" class="img-fluid">
    </a>
  </div>

</div>
</template>


<script>
// @ is an alias to /src
import { apiService } from "@/tools/api-service.js";

export default {
  name: 'HomeStreamArticle',
  props: {
    section: {
      type: Object,
      required: true
    },
    article: {
      type: Object,
      required: true
    },
    authenticated_user: {
      type: Boolean,
      required: true
    },
    loop_index: {
      type: Number,
      required: true 
    },
    ads: {
      type: Array,
      required: true 
    }
  },
  computed: {
    ads_length() {
      return this.ads.length;
    },
    get_ad_obj() {
      if ((this.loop_index !== 0) && (this.loop_index / 5 <= this.ads_length)) {
        if ((this.loop_index % 5) === 0) {
          let ad_obj = this.ads[((this.loop_index) / 5) -1];
          return ad_obj
        }
      }
      return null
    }
  },
  methods: {
    like_article(id) {
      let endpoint = `/api/articles/${ id }/like/`;
      this.article.liked_by_contributor = !this.article.liked_by_contributor;
      apiService(endpoint, "POST")
    },
    save_article(id) {
      let endpoint = `/api/articles/${ id }/add_to_favourites/`;
      this.article.saved_by_contributor = !this.article.saved_by_contributor;
      apiService(endpoint, "POST")
    },
    share_article(id) {
      let endpoint = `/api/articles/${ id }/share/`;
      this.article.shared_by_contributor = !this.article.shared_by_contributor;
      apiService(endpoint, "POST")
    }
  }

}
</script>