<template>
  <div id="app">
    <div v-for="(section, loop_index) in sections" :key="section.id">
      <hr>
      <div>
        <h3><a :href="'/section/' + section.slug"><span class="badge badge-success" style="background-color: #5cb85c !important">{{ section.name }}</span></a></h3>
        <div v-if="section.first_article">
          <HomeStreamArticle 
            :section="section"
            :article="section.first_article"
            :authenticated_user="authenticated_user"
            :ads="ads"
            :loop_index="loop_index"
          />
        </div>
        <div v-if="section.second_article">
          <HomeStreamArticle 
            :section="section"
            :article="section.second_article"
            :authenticated_user="authenticated_user"
            :ads="ads"
            :loop_index="loop_index"
          />
        </div>
        <div v-if="section.third_article">
          <HomeStreamArticle 
            :section="section"
            :article="section.third_article"
            :authenticated_user="authenticated_user"
            :ads="ads"
            :loop_index="loop_index"
          />
        </div>    
      </div>
    </div>

    <div class="my-4">
      <div v-show="loadingData">
        <img src="/static/assets/images/spinner.gif" style="display: block; margin-left: auto; margin-right: auto;">
      </div> 
      <button
        v-show="next"
        @click="getHomeStreamSections()"
        class="btn btn-outline-success"
        >Carica Ancora
      </button>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { apiService } from "@/tools/api-service.js";
import HomeStreamArticle from './components/HomeStreamArticle.vue'

export default {
  name: 'app',
  components: {
    HomeStreamArticle,
  },
  data() {
    return {
      sections: [],
      authenticated_user: false,
      next: null,
      loadingData: true,
      ads: []
    }
  },
  methods: {
    async getHomeStreamSections() {
      let endpoint = "/api/sections/sections-and-articles/";
      this.loadingData = true;
      if (this.next) {
        endpoint = this.next;
      }
      await apiService(endpoint)
        .then(data => {
          this.sections.push(...data.results)
          this.loadingData = false;
          if (data.next) {
            this.next = data.next;
          } else {
            this.next = null;
          }
        })
    },
    async setIsAuthenticated() {
      let endpoint = "/api/core/user-status/";
      await apiService(endpoint)
        .then(data => {
          this.authenticated_user = data["is_authenticated"];
        })     
    },
    async setAdsArray() {
      let endpoint = "/api/advertising/ad-global-list/";
      await apiService(endpoint)
        .then(data => {
          this.ads = data;
        })
    }
  },
   created() {
    this.getHomeStreamSections()
    this.setIsAuthenticated()
    this.setAdsArray()
  }
}
</script>